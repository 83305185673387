import React from 'react'
import { Card } from '@mui/material';

const Home = () => {
    let start_date = new Date("2022-12-01");
    //let start_date = new Date("2023-03-01")
    let curr_date = new Date();
 
    let day_dif = Math.floor((curr_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24));
    console.log(day_dif);

    let videos = [
        "https://www.youtube.com/embed/AiIBKcd4m5Q",
        "https://www.youtube.com/embed/FXdiTWLVIZo",
        "https://www.youtube.com/embed/QJO3ROT-A4E",
        "https://www.youtube.com/embed/p44G0U4sLCE",
        "https://www.youtube.com/embed/tG35R8F2j8k",
        "https://www.youtube.com/embed/EkHTsc9PU2A",
        "https://www.youtube.com/embed/oofSnsGkops",
        "https://www.youtube.com/embed/LjhCEhWiKXk",
        "https://www.youtube.com/embed/MU8B4XDI3Uw",
        "https://www.youtube.com/embed/dN44xpHjNxE",
        "https://www.youtube.com/embed/450p7goxZqg",
        "https://www.youtube.com/embed/nQJACVmankY",
        "https://www.youtube.com/embed/OT5msu-dap8",
        "https://www.youtube.com/embed/h_m-BjrxmgI",
        "https://www.youtube.com/embed/2Vv-BfVoq4g",
        "https://www.youtube.com/embed/8xg3vE8Ie_E",
        "https://www.youtube.com/embed/euCqAq6BRa4",
        "https://www.youtube.com/embed/GCdwKhTtNNw",
        "https://www.youtube.com/embed/450p7goxZqg",
        "https://www.youtube.com/embed/yef6gv-O5gg",
        "https://www.youtube.com/embed/2cBzMSPYKas",
        "https://www.youtube.com/embed/vGJTaP6anOU",
        "https://www.youtube.com/embed/CHekNnySAfM",
        "https://www.youtube.com/embed/VRU2qs82DAg",
        "https://www.youtube.com/embed/0yW7w8F2TVA",
        "https://www.youtube.com/embed/UprwkbzUX6g",
        "https://www.youtube.com/ebmed/Q9C2i_Z2ETA",
        "https://www.youtube.com/embed/rywUS-ohqeE",
        "https://www.youtube.com/embed/VdQY7BusJNU",
        "https://www.youtube.com/embed/1bGOgY1CmiU",
        "https://www.youtube.com/embed/aHl0tlUYDBI",
        "https://www.youtube.com/embed/m6pW_q1PvH0",
        "https://www.youtube.com/embed/JkK8g6FMEXE",
        "https://www.youtube.com/embed/8Ff2Z0Dq2sg",
        "https://www.youtube.com/embed/MqazV4hbu8E",
        "https://www.youtube.com/embed/Ob7vObnFUJc",
        "https://www.youtube.com/embed/1qJU8G7gR_g",
        "https://www.youtube.com/embed/g25ZjKBXw8Q",
        "https://www.youtube.com/embed/3JWTaaS7LdU",
        "https://www.youtube.com/embed/rtOvBOTyX00",
        "https://www.youtube.com/embed/qiiyq2xrSI0",
        "https://www.youtube.com/embed/lp-EO5I60KA",
        "https://www.youtube.com/embed/xIiFnueWLZA",
        "https://www.youtube.com/embed/NdYWuo9OFAw",
        "https://www.youtube.com/embed/hGW1QYK9f5M",
        "https://www.youtube.com/embed/WJtxjtH1vBM",
        "https://www.youtube.com/embed/LjhCEhWiKXk",
        "https://www.youtube.com/embed/NGFToiLtXro",
        "https://www.youtube.com/embed/MP7m5VqQ6f8",
        "https://www.youtube.com/embed/KtBbyglq37E",
        "https://www.youtube.com/embed/okGJgBVl7Vg",
        "https://www.youtube.com/embed/SvCjg2Az0Xs",
        "https://www.youtube.com/embed/IGqK3KGBnTk",
        "https://www.youtube.com/embed/Y8HOfcYWZoo",
        "https://www.youtube.com/embed/UelDrZ1aFeY",
        "https://www.youtube.com/embed/Urdlvw0SSEc",
        "https://www.youtube.com/embed/jJOzdLwvTHA",
        "https://www.youtube.com/embed/OjSUghOna3Y",
        "https://www.youtube.com/embed/EkHTsc9PU2A",
        "https://www.youtube.com/embed/2oqPZoGqlmg",
        "https://www.youtube.com/embed/KNZH-emehxA",
        "https://www.youtube.com/embed/3JWTaaS7LdU",
        "https://www.youtube.com/embed/qiiyq2xrSI0",
        "https://www.youtube.com/embed/FHG2oizTlpY",
        "https://www.youtube.com/embed/mp7oxtZAy7c",
        "https://www.youtube.com/embed/Kg7Uii4lJ6s",
        "https://www.youtube.com/embed/vGJTaP6anOU",
        "https://www.youtube.com/embed/GC5E8ie2pdM",
        "https://www.youtube.com/embed/JkK8g6FMEXE",
        "https://www.youtube.com/embed/Y8raabzZNqw",
        "https://www.youtube.com/embed/_1uunRdQ61M",
        "https://www.youtube.com/embed/Y0pdQU87dc8",
        "https://www.youtube.com/embed/vUSzL2leaFM",
        "https://www.youtube.com/embed/BBzHmOjGnBM",
        "https://www.youtube.com/embed/0Gqj_Hr8O9c",
        "https://www.youtube.com/embed/OjSUghOna3Y",
        "https://www.youtube.com/embed/HE4H0k8TDgw",
        "https://www.youtube.com/embed/I5JyQeABhZM",
        "https://www.youtube.com/embed/COiIC3A0ROM",
        "https://www.youtube.com/embed/PSoOFn3wQV4",
        "https://www.youtube.com/embed/izGwDsrQ1eQ",
        "https://www.youtube.com/embed/XpqqjU7u5Yc",
        "https://www.youtube.com/embed/hwZNL7QVJjE",
        "https://www.youtube.com/embed/UKAp-jRUp2o",
        "https://www.youtube.com/embed/6inwzOooXRU",
        "https://www.youtube.com/embed/rKEzFdFY2w8",
        "https://www.youtube.com/embed/r9jmusgMgro",
        "https://www.youtube.com/embed/VdQY7BusJNU",
        "https://www.youtube.com/embed/AMD2TwRvuoU",
        "https://www.youtube.com/embed/r3Pr1_v7JcE",
        "https://www.youtube.com/embed/ewxmv2tyeRs",
        "https://www.youtube.com/embed/EwbigJGQe2A",
        "https://www.youtube.com/embed/iUI5uT9b_IQ",
        "https://www.youtube.com/embed/kE0pwJ5PMDg",
        "https://www.youtube.com/embed/dp-pqhREkEA",
        "https://www.youtube.com/embed/wDZFf0pm0SE",
        "https://www.youtube.com/embed/UrIiLvg58SY",
        "https://www.youtube.com/embed/xbWwTzPUh50",
        "https://www.youtube.com/embed/WQnAxOQxQIU",
        "https://www.youtube.com/embed/KNZH-emehxA",
        "https://www.youtube.com/embed/s3bksUSPB4c",
        "https://www.youtube.com/embed/sGXTF6bs1IU",
        "https://www.youtube.com/embed/-bLhXkN8gBs",
        "https://www.youtube.com/embed/27p2BmqxAWs",
        "https://www.youtube.com/embed/fV8vB1BB2qc",
        "https://www.youtube.com/embed/LjhCEhWiKXk",
        "https://www.youtube.com/embed/HhOw0WYfMXI",
        "https://www.youtube.com/embed/25rL-ooWICU",
        "https://www.youtube.com/embed/Urdlvw0SSEc",
        "https://www.youtube.com/embed/Vzo-EL_62fQ",
        "https://www.youtube.com/embed/nIjVuRTm-dc",
        "https://www.youtube.com/embed/1SCOimBo5tg",
        "https://www.youtube.com/embed/qDuNofc6ZnU",
        "https://www.youtube.com/embed/KLVq0IAzh1A",
        "https://www.youtube.com/embed/jenWdylTtzs",
        "https://www.youtube.com/embed/0Gl2QnHNpkA",
        "https://www.youtube.com/embed/ltRwmgYEUr8",
        "https://www.youtube.com/embed/o-yq5OKkhqA",
        "https://www.youtube.com/embed/AMfWJF2EZrI",
        "https://www.youtube.com/embed/7NJqUN9TClM",
        "https://www.youtube.com/embed/oofSnsGkops",
        "https://www.youtube.com/embed/p2Rch6WvPJE",
        "https://www.youtube.com/embed/kC6NEuNdGq0",
        "https://www.youtube.com/embed/ipAnwilMncI",
        "https://www.youtube.com/embed/SPUJIbXN0WY",
        "https://www.youtube.com/embed/ev2L6pPkp4Y",
        "https://www.youtube.com/embed/Ju8Hr50Ckwk",
        "https://www.youtube.com/embed/h9ZGKALMMuc",
        "https://www.youtube.com/embed/UKAp-jRUp2o",
        "https://www.youtube.com/embed/kBmwTyz5DLs",
        "https://www.youtube.com/embed/YVIL6mTxSho",
        "https://www.youtube.com/embed/KCy7lLQwToI",
        "https://www.youtube.com/embed/x6QZn9xiuOE",
        "https://www.youtube.com/embed/3j3okb3kuts",
        "https://www.youtube.com/embed/L6-uJLteKek",
        "https://www.youtube.com/embed/dO1rMeYnOmM",
        "https://www.youtube.com/embed/oR6okRuOLc8",
        "https://www.youtube.com/embed/9X_ViIPA-Gc",
        "https://www.youtube.com/embed/AiIBKcd4m5Q",
        "https://www.youtube.com/embed/IpT-FW_G8-A"
    ]

    console.log("videos length: ", videos.length)
    console.log("showing video: ", videos[Math.min(videos.length-1, day_dif)])
    return (
        <React.Fragment>
            <div className="video-responsive">
            <iframe width="680" height="370" src={videos[Math.min(videos.length-1, day_dif)]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </React.Fragment>
    )
}

export default Home;