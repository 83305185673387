
import React from 'react'
import ResponsiveAppBar from '../components/navigations/ResponsiveMenuBar'
import Theme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';

import './Layout.css'

const Layout = (props: any) => {
  return(
      <React.Fragment>
        
          <ResponsiveAppBar/>

          <main className="main-content" style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}}>
            {props.children}
          </main>
      </React.Fragment>
  );
}

export default Layout;