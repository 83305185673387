import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

const Gallery = () => {
    return (
        <React.Fragment>
            <h3>Coming Soon </h3>
        </React.Fragment>
    )
}

export default Gallery;