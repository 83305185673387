import React from 'react';
import {RadioGroup, Radio} from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

const Mood = () => {
    return (
        <React.Fragment>
            <h3>Coming Soon</h3>
        </React.Fragment>
    )
}

/*
<h3>How are you doing today Lyly?</h3><br/>
            <p>1:                                                                                                      10:</p><br/>
            <RadioGroup row><Radio /><Radio /><Radio /><Radio /><Radio /><Radio /><Radio /><Radio /><Radio /><Radio /></RadioGroup>
            */

export default Mood;