import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom'
import Layout from './layout/Layout'
import Home from './components/pages/Home'
import Gallery from './components/pages/Gallery'
import Messages from './components/pages/Messages'
import Mood from './components/pages/Mood'

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Layout>
            <Routes>
              <Route path={'/Gallery'} element={<Gallery/>}></Route>
              <Route path={'/Messages'} element={<Messages/>}></Route>
              <Route path={'/'} element={<Home/>}></Route>
              <Route path={'/Mood'} element={<Mood/>}></Route>
            </Routes>
          </Layout>
        </Router>
      </header>
    </div>
  );
}

export default App;
