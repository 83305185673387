import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Container, Box } from '@mui/material'
import { AutoMode } from '@mui/icons-material'
import { render } from '@testing-library/react';

function CarouselDisplay(props: any)
{
    let start_date = new Date("2022-12-01");
    let curr_date = new Date();

    let day_dif = Math.floor((curr_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24));

    console.log(day_dif);

    var items = [
        {
            name: "Day 1/180: Anniversary",
            description: "I love you because you are beautiful inside and out."
        },

        {
            name: "Day 2/180",
            description: "I love you because you bring me food when I feel down :)"
        },

        {
            name: "Day 3/180",
            description: "I love you because you are so cute it makes my heart melt hehe"
        },

        {
            name: "Day 4/180",
            description: "I love you because you always comfort me when I apologize even when we miss the firewworks"
        },

        {
            name: "Day 5/180",
            description: "I love you because of the way you skip when you're happy"
        },

        {
            name: "Day 6/180",
            description: "I love you because of how happy you look when we eat together"
        },

        {
            name: "Day 7/180",
            description: "I love you because you get me such thoughtful gifts (timbuktu bag is so cool)"
        },

        {
            name: "Day 8/180",
            description: "I love you because you don't get mad when plans don't work out :("
        },

        {
            name: "Day 9/180",
            description: "I love you because you gave me my first flowers >_<"
        },
        {
            name: "Day 10/180",
            description: "I love you because you listen to me even when I just want to complain"
        },
        {
            name: "Day 11/180",
            description: "I love you because I always have fun when I'm with you"
        },
        {
            name: "Day 12/180",
            description: "I love you because you give the best hugs (even if I don't hehe)"
        },
        {
            name: "Day 13/180",
            description: "I love you because you let me comb your hair"
        },
        {
            name: "Day 14/180",
            description: "I love you because you're so cute omg"
        },
        {
            name: "Day 15/180",
            description: "I love you because you caltrain down to see me!"
        },
        {
            name: "Day 16/180",
            description: "I love you because you're understanding. You listen to my gripes and we always talk through our problems together"
        },
        {
            name: "Day 17/180",
            description: "I love you because you're considerate to a fault"
        },
        {
            name: "Day 18/180",
            description: "I love you because you're always willing to talk"
        },
        {
            name: "Day 19/180",
            description: "I love you because you always find the best places to eat :O"
        },
        {
            name: "Day 20/180",
            description: "I love you because you don't know how beautiful you really are"
        },
        {
            name: "Day 21/180",
            description: "I love you because even if you get mad at me we don't argue"
        },
        {
            name: "Day 22/180",
            description: "I love you because you always bright up my day (sometimes over ft but especially in person)"
        },
        {
            name: "Day 23/180",
            description: "I love you because you are always patient with me"
        },
        {
            name: "Day 24/180",
            description: "I love you because you are sometimes so silly and cute"
        },
        {
            name: "Day 25/180",
            description: "I love you because you are such an amazing planner even if you're family doesn't follow your plan hehe"
        },
        {
            name: "Day 26/180",
            description: "I love you because you're just the most awsesome coolest person ever no questions asked"
        },
        {
            name: "Day 27/180",
            description: "I love you because your smile is beautiful and makes me happy"
        },
        {
            name: "Day 28/180",
            description: "I love you because you listen to my problems and make efforts to help me"
        },
        {
            name: "Day 29/180",
            description: "I love you because you're always laughing at my antics"
        },
        {
            name: "Day 30/180",
            description: "I love you because you don't think you're cute even when you definitely are"
        },
        {
            name: "Day 31/180",
            description: "I love you because you don't know why I'm smiling"
        },
        {
            name: "Day 32/180",
            description: "I love you because you split food with me"
        },
        {
            name: "Day 33/180",
            description: "I love you because you're so cute when I wake you up"
        },
        {
            name: "Day 34/180",
            description: "I love you because you ft with me while I cook"
        },
        {
            name: "Day 35/180",
            description: "I love you because you make me such cute gift baskets"
        },
        {
            name: "Day 36/180",
            description: "I love you because you take silly photos of me when I'm not paying attention (I'm gonna see that album one day)"
        },
        {
            name: "Day 37/180",
            description: "I love you because you get so cute when you're embarassed"
        },
        {
            name: "Day 38/180",
            description: "I love you because you cook with me and eat with me! Even if you use too much pepper and not enough salt :-)"
        },
        {
            name: "Day 39/180",
            description: "I love you because of the way you say good morning"
        },
        {
            name: "Day 40/180",
            description: "I love you because of the way you say goodnight"
        },
        {
            name: "Day 41/180",
            description: "I love you because you stay on ft with me through the night"
        },
        {
            name: "Day 42/180",
            description: "I love you because you make me smile when I'm sad"
        },
        {
            name: "Day 43/180",
            description: "I love you because you're so cute when you're sleepy"
        },
        {
            name: "Day 44/180",
            description: "I love you because you keep me company while I work"
        },
        {
            name: "Day 45/180",
            description: "I love you because you hide your face even tho you’re cute"
        },
        {
            name: "Day 46/180",
            description: "I love you because you hold my hand while we drive"
        },
        {
            name: "Day 47/180",
            description: "I love you because you always have stories to share "
        },
        {
            name: "Day 48/180",
            description: "I love you because you are so organized!!!"
        },
        {
            name: "Day 49/180",
            description: "I love you because you are such a good planner"
        },
        {
            name: "Day 50/180",
            description: "I love you because you let me wash your hair"
        },
        {
            name: "Day 51/180",
            description: "I love you because you’re always receptive to the things I want to do"
        },
        {
            name: "Day 52/180",
            description: "I love you because you’re adventurous"
        },
        {
            name: "Day 53/180",
            description: "I love you because you’re okay having a relaxed day at home"
        },
        {
            name: "Day 54/180",
            description: "I love you because you come visit me!!!"
        },
        {
            name: "Day 55/180",
            description: "I love you because you skip when you’re happy"
        },
        {
            name: "Day 56/180",
            description: "I love you because your smile is so cute"
        },
        {
            name: "Day 57/180",
            description: "I love you because you make me feel fuzzy hehe"
        },
        {
            name: "Day 58/180",
            description: "I love you because you use super cute stickers"
        },
        {
            name: "Day 59/180",
            description: "I love you because you love food like me :)"
        },
        {
            name: "Day 60/180",
            description: "I love you because you like me even when I’m not romantic"
        },
        {
            name: "Day 61/180",
            description: "I love you because cuddling with you is nice"
        },
        {
            name: "Day 62/180",
            description: "I love you because  you ft me just to talk"
        },
        {
            name: "Day 63/180",
            description: "I love you because we do such fun things together"
        },
        {
            name: "Day 64/180",
            description: "I love you because you don’t care if I smell lol"
        },
        {
            name: "Day 65/180",
            description: "I love you because you always make time for me"
        },
        {
            name: "Day 66/180",
            description: "I love you because you let me stay over :0"
        },
        {
            name: "Day 67/180",
            description: "I love you because waterproof covers ;)"
        },
        {
            name: "Day 68/180",
            description: "I love you because you make me happy"
        },
        {
            name: "Day 69/180",
            description: "I love you because you don’t get mad even if we’re late"
        },
        {
            name: "Day 70/180",
            description: "I love you because you brought me snacks when I was feeling down"
        },
        {
            name: "Day 71/180",
            description: "I love you because of the way you flop into bed"
        },
        {
            name: "Day 72/180",
            description: "I love you because you are my sunshine"
        },
        {
            name: "Day 73/180",
            description: "I love you because you let me read you bedtime stories"
        },
        {
            name: "Day 74/180",
            description: "I love you because you always make me laugh"
        },
        {
            name: "Day 75/180",
            description: "I love you because you smile so cute"
        },
        {
            name: "Day 76/180",
            description: "I love you because you bring me to family dinner"
        },
        {
            name: "Day 77/180",
            description: "I love you because you are always considerate of my feelings"
        },
        {
            name: "Day 78/180",
            description: "I love you because you comfort me when I'm feeling down"
        },
        {
            name: "Day 79/180",
            description: "I love you because you are so cute when you're sleepy"
        },
        {
            name: "Day 80/180",
            description: "I love you because you taught me majhong"
        },
        {
            name: "Day 81/180",
            description: "I love you because you always go along with my ideas"
        },
        {
            name: "Day 82/180",
            description: "I love you because you make me feel appreciated"
        },
        {
            name: "Day 83/180",
            description: "I love you because I always have fun with you"
        },
        {
            name: "Day 84/180",
            description: "I love you because we always eat good food together"
        },
        {
            name: "Day 85/180",
            description: "I love you because your voice is so soft"
        },
        {
            name: "Day 86/180",
            description: "I love you because you are the light of my life"
        },
        {
            name: "Day 87/180",
            description: "I love you because I can alwyas rely on you to be there for me"
        },
        {
            name: "Day 88/180",
            description: "I love you because you like to ft me"
        },
        {
            name: "Day 89/180",
            description: "I love you because you are always forgiving and understanding of my mistakes"
        },
        {
            name: "Day 90/180",
            description: "I love you because you're always willing to go on adventures together"
        },
        {
            name: "Day 91/180",
            description: "I love you because we go on such fun trips together"
        },
        {
            name: "Day 92/180",
            description: "I love you because you make me feel silly hehe"
        },
        {
            name: "Day 93/180",
            description: "I love you because you're always honest with me"
        },
        {
            name: "Day 94/180",
            description: "I love you because you are always supportive of the things I'm passionate about"
        },
        {
            name: "Day 95/180",
            description: "I love you because of how you hide under the covers"
        },
        {
            name: "Day 96/180",
            description: "I love you because you are always down to cuddle :)"
        },
        {
            name: "Day 97/180",
            description: "I love you because you make me smile"
        },
        {
            name: "Day 98/180",
            description: "I love you because you sometimes get too tired n sit on the floor"
        },
        {
            name: "Day 99/180",
            description: "I love you because we always play silly games together like push off the bed"
        },
        {
            name: "Day 100/180",
            description: "I love you because even if I frustrate you, you never take it out on me"
        },
        {
            name: "Day 101/180",
            description: "I love you because you are sensitive and in touch with your feelings"
        },
        {
            name: "Day 102/180",
            description: "I love you because you are amazing even if you don't realize it"
        },
        {
            name: "Day 103/180",
            description: "I love you because you get me great gifts"
        },
        {
            name: "Day 104/180",
            description: "I love you because you drive/caltrain to come to see me"
        },
        {
            name: "Day 105/180",
            description: "I love you always ask about what I'm working on even if it's boring"
        },
        {
            name: "Day 106/180",
            description: "I love you because you make me want to be better"
        },
        {
            name: "Day 107/180",
            description: "I love you because you make me feel and act cute"
        },
        {
            name: "Day 108/180",
            description: "I love you because you showed me new stickers"
        },
        {
            name: "Day 109/180",
            description: "I love you because you are intelligent and hardworking"
        },
        {
            name: "Day 110/180",
            description: "I love you because we fall asleep together on ft"
        },
        {
            name: "Day 111/180",
            description: "I love you because you make me feel cool and confident"
        },
        {
            name: "Day 112/180",
            description: "I love you because you're not afraid to be a chad haha"
        },
        {
            name: "Day 113/180",
            description: "I love you because you introduce me to your friends"
        },
        {
            name: "Day 114/180",
            description: "I love you because you like spending time just the two of us"
        },
        {
            name: "Day 115/180",
            description: "I love you because you always want to play"
        },
        {
            name: "Day 116/180",
            description: "I love you because you make an effort to get to know my friends"
        },
        {
            name: "Day 117/180",
            description: "I love you because you give me lap pillows hehe"
        },
        {
            name: "Day 118/180",
            description: "I love you because you don't get annoyed when I shower you with kisses"
        },
        {
            name: "Day 119/180",
            description: "I love you because you always make my day a little brighter"
        },
        {
            name: "Day 120/180",
            description: "I love you because you always compliment my cooking even if I don't think it's that good"
        },
        {
            name: "Day 121/180",
            description: "I love you because you are more beautiful than words can describe"
        },
        {
            name: "Day 122/180",
            description: "I love you because when we hold hands, I feel fuzzy inside"
        },
        {
            name: "Day 123/180",
            description: "I love you because you laugh at my stupid jokes even if you say I'm not funny"
        },
        {
            name: "Day 124/180",
            description: "I love you because you are always there for me"
        },
        {
            name: "Day 125/180",
            description: "I love you because you jump on me"
        },
        {
            name: "Day 126/180",
            description: "I love you because you are romantic"
        },
        {
            name: "Day 127/180",
            description: "I love you because I know you are the one fo rme"
        },
        {
            name: "Day 128/180",
            description: "I love you because everything you do makes me love you more"
        },
        {
            name: "Day 129/180",
            description: "I love you because I feel at peace when I'm with you"
        },
        {
            name: "Day 130/180",
            description: "I love you because you are the best thing that has ever happened to me"
        },
        {
            name: "Day 131/180",
            description: "I love you because you are always down to try out some silly activity with me"
        },
        {
            name: "Day 132/180",
            description: "I love you because everything's better when we're together"
        },
        {
            name: "Day 133/180",
            description: "I love you because you make me want to be the best version of myself"
        },
        {
            name: "Day 134/180",
            description: "I love you because you ask to pay for food"
        },
        {
            name: "Day 135/180",
            description: "I love you because you are the most amazing person I know"
        },
        {
            name: "Day 136/180",
            description: "I love you because you are the most beautiful person I know, inside and outsid"
        },
        {
            name: "Day 137/180",
            description: "I love you because you stay with me on ft until the morning "
        },
        {
            name: "Day 138/180",
            description: "I love you because you send me funny videos"
        },
        {
            name: "Day 139/180",
            description: "I love you because you make me feel appreciated"
        },
        {
            name: "Day 140/180",
            description: "I love you because you always watch my silly meme videos"
        },
        {
            name: "Day 141/180",
            description: "I love you because you're so cute when you run"
        },
        {
            name: "Day 142/180",
            description: "I love you because you enjoy just being with me"
        },
        {
            name: "Day 143/180",
            description: "I love you because we watch anime together"
        },
        {
            name: "Day 144/180",
            description: "I love you because you always listen to me talk about my day"
        },
        {
            name: "Day 145/180",
            description: "I love you because you can always tell how I'm feeling"
        },
        {
            name: "Day 146/180",
            description: "I love you because you give the best hugs"
        },
        {
            name: "Day 147/180",
            description: "I love you because you make me want to be more romantic"
        },
        {
            name: "Day 148/180",
            description: "I love you because you're cute even when you're pouting"
        },
        {
            name: "Day 149/180",
            description: "I love you because you're always trying to make me happy"
        },
        {
            name: "Day 150/180",
            description: "I love you because u r the one for me"
        },
        {
            name: "Day 151/180",
            description: "I love you because you're the cutest when you wake up"
        },
        {
            name: "Day 152/180",
            description: "I love you because you're such a cool kid: the coolest kid I know"
        },
        {
            name: "Day 153/180",
            description: "I love you because you make me smile every time I see you"
        },
        {
            name: "Day 154/180",
            description: "I love you because you cook with me"
        },
        {
            name: "Day 155/180",
            description: "I love you because you let me talk you to sleep"
        },
        {
            name: "Day 156/180",
            description: "I love you because you stay up late with me"
        },
        {
            name: "Day 157/180",
            description: "I love you because you stay with me on ft even if it's just to work together"
        },
        {
            name: "Day 158/180",
            description: "I love you because you are so special to me"
        },
        {
            name: "Day 159/180",
            description: "I love you because I feel so safe and warm when we cuddle"
        },
        {
            name: "Day 160/180",
            description: "I love you because you did my eyebrows >:)"
        },
        {
            name: "Day 161/180",
            description: "I love you because you rub my head when I'm tired"
        },
        {
            name: "Day 162/180",
            description: "I love you because you teach me how to be a better communicator"
        },
        {
            name: "Day 163/180",
            description: "I love you because you always listen to my silly ideas"
        },
        {
            name: "Day 164/180",
            description: "I love you because you laugh at my stupid jokes hahaha"
        },
        {
            name: "Day 165/180",
            description: "I love you because you are such a hard worker (working so many jobs at once + clinical)"
        },
        {
            name: "Day 166/180",
            description: "I love you because you let me eat with your family and taught me mahjong"
        },
        {
            name: "Day 167/180",
            description: "I love you because you let me give you backrubs (and you give me backrubs too)"
        },
        {
            name: "Day 168/180",
            description: "I love you because we watch anime together"
        },
        {
            name: "Day 169/180",
            description: "I love you because you copy me when I'm being silly"
        },
        {
            name: "Day 170/180",
            description: "I love you because I can be myself around you"
        },
        {
            name: "Day 171/180",
            description: "I love you because we can just sit quietly and enjoy being together"
        },
        {
            name: "Day 172/180",
            description: "I love you because we can talk about anything"
        },
        {
            name: "Day 173/180",
            description: "I love you because sometimes we wake up together on ft"
        },
        {
            name: "Day 174/180",
            description: "I love you because it's just nice to have someone to work with on ft"
        },
        {
            name: "Day 175/180",
            description: "I love you because you made me such a cute graduation bundle"
        },
        {
            name: "Day 176/180",
            description: "I love you because you always listen to me when I need to vent"
        },
        {
            name: "Day 177/180",
            description: "I love you because we always do fun things together (like seeing mr bison)"
        },
        {
            name: "Day 178/180",
            description: "I love you because you take naps with me!!!"
        },
        {
            name: "Day 179/180",
            description: "I love you because the way you jump on me makes me feel so loved"
        },
        {
            name: "Day 180/180",
            description: "Lyly, I love you so much. I'm so happy to have you in my life. I can't wait to spend the rest of my life with you. I love you so much."
        },

    ]

    var render_items : Array<any> = []

    for(var i = 0; i <= day_dif; i++){
        if (i >= items.length){
            break;
        }
        render_items.push(items[i]);
    }

    return (
        <Container>
            <Container sx={{
                width: 0.8,
                height: 1}}>
                <Carousel autoPlay={false} height={500} navButtonsAlwaysVisible={true} index={render_items.length-1}>
                    {
                        render_items.map( (item, i) => <Item key={i} item={item} /> )
                    }
                </Carousel>
            </Container>
            <Container sx = {{fontFamily: 'monospace'}}>6 months of reasons I love you</Container>
        </Container>
    )
}

function Item(props: any)
{
    return (
        <Container sx={{
            width: 0.8,
            height: 0.9}}>
             <Paper elevation={24} sx={{height:1, backgroundColor:'#FF8FB1'}}>
                    <p style = {{fontSize: 'medium'}}>{props.item.name}</p>
                    <div style={{display: 'flex', alignItems: 'center',
        justifyContent: 'center', height: '35vh', fontFamily: 'cursive', padding: '10px'}}><h2>{props.item.description}</h2></div>
                    
            </Paper>
        </Container>
    )
}

export default CarouselDisplay;