import React from 'react'
import CarouselDisplay from '../displays/Carousel'
const Messages = () => {
    return (
        <React.Fragment>
            <CarouselDisplay/>
        </React.Fragment>
    )
}

export default Messages;